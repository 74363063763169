import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //************************************//
  //  DELETE REQUEST IN HOLIDAY TABLE  //
  //***********************************//

  Delete(index) {
    return Promise.resolve(
      axios.delete(
        `${keys.auth.BaseUrl}/deleteHoliday/${index}`,
        keys.auth.apiKey
      )
    );
  },
  //*********************************//
  //  GET REQUEST IN HOLIDAY TABLE  //
  //********************************//

  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getHoliday`, keys.auth.apiKey)
    );
  },
  //**********************************//
  //  POST REQUEST IN HOLIDAY TABLE  //
  //********************************//

  Post(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertHoliday`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //************************************//
  //  UPDATE REQUEST IN HOLIDAY TABLE  //
  //***********************************//

  Update(data, index) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/updateHoliday`,
        { data: data, index },
        keys.auth.apiKey
      )
    );
  },
};

export default httpRequest;
