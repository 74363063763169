import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //******************************************************//
  //  GET CATEGORY REQUEST IN CategoryInformation TABLE  //
  //*****************************************************//
  getCategory(item) {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getCategory/${item}`, keys.auth.apiKey)
    );
  },

  updateCategory(item) {
    delete item.InquiryCategoryDelete
    return Promise.resolve(
      axios.post(`${keys.auth.BaseUrl}/updateInquiryMaster`,item,keys.auth.apiKey)
    );
  },
};

export default httpRequest;
