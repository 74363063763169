import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //*********************************//
  //      GET INQUIRY CATEGORY      //
  //********************************//

  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getInquiryCategory`, keys.auth.apiKey)
    );
  },

  GetSelectCategories(){
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getSelectInquiryCategory`, keys.auth.apiKey)
    );
  },

  GetByCategories(category){
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getSelectByInquiryCategory/${category}`, keys.auth.apiKey)
    );
  },
  //*********************************//
  //      GET INQUIRY CATEGORY      //
  //********************************//
  insert(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertInquiryCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //*********************************//
  //   UPDATE INQUIRY CATEGORY      //
  //********************************//
  Update(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/editInquiryCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
 //*********************************//
  //   SOFT DELETE INQUIRY CATEGORY      //
  //********************************//
  SoftDelete(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/softDeleteInquiryCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //*************************************//
  //   UPDATE SWAP INQUIRY CATEGORY      //
  //*************************************//
  UpdateSwap(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/editSwapInquiryCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
};

export default httpRequest;
