<template>
  <v-container class="pa-7" v-scrollbar :style="cardStyle">
    <v-form enctype="multipart/form-data">
      <!-- INFORMATION -->
      <v-row>
        <v-col class="text-center">
          <div class="float-md-left" style="margin-top: -1rem">
            <h2>{{ $t("composed.confirmationForm") }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!-- INQUIRY TYPE -->
      <v-row class="bottomArrangements">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.inquiryType") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{ $t("composed.mandatory") }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-select
            class="mb-n5"
            v-model="toStore.InquiryType"
            :items="radioValue"
            :item-text="language == 'en' ? 'English' : 'Japanese'"
            @change="inquiretype(toStore.InquiryType)"
            item-value="Japanese"
            outlined
            color="#df6464"
            dense
            ref
          ></v-select>
        </v-col>
      </v-row>
      <br />
      <!-- TYPE OF CONFIRMATION  -->
      <v-row class="bottomArrangement mb-n10">
        <v-col>
          <span style="color: grey; font-size: 13px"> {{ $t("composed.due") }} </span
          ><span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{ $t("composed.mandatory") }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-menu
            content-class="elevation-0"
            v-model="menu"
            class="text-center"
            :close-oncontent-click="false"
            transition="scale-transition"
            offet-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toStore.TypeOfConfirmation"
                color="#df6464"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                dense
                clearable
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="toStore.TypeOfConfirmation"
              :allowed-dates="allowedDates"
              @input="menu = false"
              :min="dateToday"
              color="#df6464"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- INQUIRY ITEM -->
      <v-row class="bottomArrangements">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.inquiryItem") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{ $t("composed.mandatory") }}</span
          ><span style="color: grey; font-size: 13px">)</span><br />
          <v-autocomplete
            color="#df6464"
            v-model="toStore.InquiryItem"
            :items="arrInquireItem"
            item-text="categoryItem"
            item-value="InquiryItem"
            :disabled="arrInquireItem.length == 0 ? true : false"
            dense
            outlined
            clearable
            :label="$t('composed.selectItem')"
            @change="email()"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- CUSTOMER CODE -->
      <v-row class="bottomArrangements">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.customerCode") }} </span
          ><span style="color: red; font-size: 10px">{{ $t("composed.mandatory") }}</span>
          <v-text-field
            v-model="toStore.CustomerCode"
            color="#df6464"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- RULEBOOK -->
      <v-row class="bottomArrangements">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.rulebookNumber") }}
          </span>
          <v-text-field
            v-model="toStore.RuleBookNumber"
            color="#df6464"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- CONTENT -->
      <v-row class="bottomArrangements">
        <v-col>
          <span style="color: grey; font-size: 13px">
            {{ $t("composed.content") }}
          </span>
          <span style="color: grey; font-size: 13px">(</span
          ><span style="color: red; font-size: 10px">{{ $t("composed.mandatory") }}</span
          ><span style="color: grey; font-size: 13px">)</span>
          <v-textarea
            v-model="content"
            color="#df6464"
            clearable
            counter
            no-resize
            auto-grow
            dense
            outlined
            @keyup="messageContent"
          ></v-textarea>
        </v-col>
      </v-row>
      <!-- ATTACHMENT -->
      <v-row class="bottomArrangements">
        <v-col>
          <span class="mr-2" v-if="UserAttachment.length > 0" style="color: grey">{{
            language == "en" ? "Preview" : "プレビュー"
          }}</span>
          <div v-for="(val, index) in UserAttachment" :key="index">
            <v-chip
              class="ma-2"
              close
              color="#df6464"
              text-color="white"
              @click:close="removeAttachment(index, val)"
              @click="DownloadData(val.OriginalName)"
            >
              {{ val.ClientName }}
            </v-chip>
          </div>
          <file-pond
            v-model="tempFile"
            :onaddfile="handleFileUploads"
            class="mb-5 mt-1"
            ref="pond"
            :label-idle="$t('composed.attachment')"
            allow-multiple="true"
            dropOnPage="true"
          />
        </v-col>
      </v-row>
      <br />
      <!-- SUBMIT -->
      <v-row>
        <v-col cols="12" offset-md="12">
          <v-btn
            block
            color="#00cba3"
            width="100%"
            outlined
            :loading="btnLoading"
            @click.native="submitEmail"
          >
            {{ $t("composed.send") }}</v-btn
          >
        </v-col>
      </v-row>
      <br />
    </v-form>

    <!-- WAITING DIALOG -->
    <v-dialog
      v-model="waitingDialog"
      v-if="waitingDialog"
      persistent
      max-width="200px"
      transition="fade"
    >
      <v-card class="pa-3 text-center">
        <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
          language == "en" ? "Plase Wait..." : "お待ちください..."
        }}</b>
        <v-img src="../assets/loading.gif" width="50" height="50" style="margin: auto">
        </v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "../store";
import moment from "moment";
import shortid from "shortid";
import attachment from "../functions/Attachment";
import objects from "../functions/alert";
import DraftTable from "../functions/httpRequest/ExternalConnection/DraftTable";
import CategoryTable from "../functions/httpRequest/ExternalConnection/CategoryInformationTable";
import ConfirmationTable from "../functions/httpRequest/ExternalConnection/ConfirmationTable";
import EmailTable from "../functions/email";
import InquiryCategory from "../functions/httpRequest/ExternalConnection/InquiryCategory";
import HolidayTable from "../functions/httpRequest/ExternalConnection/HolidayTable";
import EmailTemplate from "../functions/emailTemplate";
import InquiryStatusTable from "../functions/httpRequest/ExternalConnection/InquiryStatusTable";
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Create component
const FilePond = vueFilePond();

export default {
  data() {
    return {
      btnLoading: false,
      menu: false,
      waitingDialog: false,
      radioValue: null,
      userID: "",
      name: "",
      content: "",
      socket: "",
      customerCode: "",
      ruleBookNumber: "",
      userType: store.state.userinfo.UserType,
      dateToday: moment().format("YYYY-MM-DD"),
      arrInquireItem: [],
      s3key: [],
      files: [],
      tempFile: [],
      attachment: [],
      UserAttachment: [],
      holidaySchedule: [],
      toStore: {},
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  components: {
    FilePond,
  },
  async created() {
    // Get Holiday
    this.getHoliday();
    // Get inquiry Type
    this.getInquiryCategory();

    // Store Data
    this.toStore.Department = "HRD開発";

    // Check User Level
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }
    // GENERATE DRAFT
    await this.getDraftRecord();

    //Include Socket IO
    this.socket = this.$io;

    // CHECK FOR WIDTH AND HEIGHT IN RESIZING
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    // WATCH FOR PARAM CHANGING
    "$route.params.id"() {
      this.getDraftRecord();
    },
  },
  computed: {
    // LAGUANGE LOCALE

    language() {
      return this.$i18n.locale;
    },
    // HANDLE RESPONSIVENESS OF MAIN DIV

    cardStyle() {
      if (this.window.width < 960) return "max-height: calc(125vh - 350px)";

      return "max-height: calc(119vh - 350px)";
    },
  },
  methods: {
    //***************************//
    //   GET HOLIDAY RECORD API  //
    //***************************//

    getHoliday() {
      HolidayTable.Get().then((res) => (this.holidaySchedule = res.data));
    },
    //****************************************//
    //   DISABLE HOLIDAY AND SUNDAY IN DATES  //
    //****************************************//

    allowedDates(val) {
      const check = this.holidaySchedule.filter((el) => el.HolidayDate == val);

      if (moment(val).day() === 0) return false;
      if (check.length > 0) return false;
      else return true;
    },
    //*********************//
    //   GET CATEGORY API  //
    //*********************//

    async getInquiryCategory() {
      await InquiryCategory.Get({
        filter: {
          DeletedDate: {
            eq: null,
          },
        },
      }).then(
        (res) =>
          (this.radioValue = res.data
            .filter((item) => item.DeletedDate === null)
            .sort((a, b) => (a.inquiryID < b.inquiryID ? -1 : 1)))
      );
    },
    //************************************//
    //   SEND EMAIL/SYSTEM  CONFIRMATION  //
    //************************************//

    async submitEmail() {
      if (!this.toStore.InquiryType)
        this.$toast.error(this.$t("alert.inquiryType"), objects.alert);
      else if (!this.toStore.TypeOfConfirmation)
        this.$toast.error(this.$t("alert.typeOfConfirmation"), objects.alert);
      else if (!this.toStore.InquiryItem)
        this.$toast.error(this.$t("alert.inquiryItem"), objects.alert);
      else if (!this.toStore.CustomerCode)
        this.$toast.error(this.$t("alert.inquiryCustomerCode"), objects.alert);
      else if (!this.toStore.MessageContent)
        this.$toast.error(this.$t("alert.inquiryMessage"), objects.alert);
      else {
        this.waitingDialog = true;
        const GeneratedID = shortid.generate(); //GENERATE CONFIRMATION ID
        this.toStore.Attachment = [];

        // CHECK FOR ATTACHMENT CONTENT
        if (this.UserAttachment.length > 0) {
          for (let x in this.UserAttachment) {
            this.toStore.Attachment.push(this.UserAttachment[x].OriginalName);
            this.s3key.push(this.UserAttachment[x].OriginalName);
          }
        }
        // FOR REALTIME SENDING
        this.socket.emit("sendingMessageToServer", {
          name: this.name,
          afftectedUser: this.toStore.To,
          uniqueID: GeneratedID,
          userID: this.userID,
          message: this.toStore.MessageContent,
          time: moment().format("YYYY-MM-DD HH:mm"),
        });

        // PASS VARIABLE TO BE SAVE IN DATABASE
        this.toStore.SenderName = this.name;
        this.toStore.SenderID = this.userID;
        this.toStore.Status = "Not Started";
        this.toStore.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
        this.toStore.ConfirmationID = GeneratedID;
        this.toStore.Subject = this.toStore.CustomerCode;

        // CHECK FOR EXISTING ATTACHMENT
        if (this.UserAttachment.length > 0) {
          const cutPath = this.toStore.Path.split("/");
          const newPath = `Attachment/${cutPath[1]}/${cutPath[2]}/${cutPath[3]}`;
          // SEND COPY INFO FROM API
          await attachment.copy(
            `${this.toStore.Path}`,
            `${newPath}`,
            this.UserAttachment
          );
          // DELETE FROM DRAFT IN S3
          await attachment.delete(this.toStore.Path, this.UserAttachment);
          const dateToday = moment().format("YYYY/MM/DD");
          this.toStore.Path = `Attachment/${dateToday}`;
        }

        // CHECK IF HAVE A NEW ATTACHMENT
        if (this.files.length > 0) {
          this.toStore.Path = `Attachment/${moment().format("YYYY/MM/DD")}`;

          await attachment.upload(
            "Attachment",
            this.files,
            (filename) => {
              this.toStore.Attachment.push(...filename);
            },
            async (keyName) => {
              keyName.forEach((el) => this.s3key.push(el));
              this.s3key = this.s3key.map((el) => `${this.toStore.Path}/${el}`);
              await sendingEmail();
              this.waitingDialog = false;
              this.$router.push("/inbox");
            }
          );
        }

        // Send Reply info into BackEnd
        const promises = [];

        promises.push(DraftTable.Delete(this.toStore.ID));

        // CHECK IF "To" HAVE A VALUE
        if (this.toStore.To) this.toStore.SenderEmail = store.state.userinfo.Email;
        promises.push(
          ConfirmationTable.Post(this.toStore),
          DraftTable.InsertConDraft(this.toStore),
          InquiryStatusTable.Post(this.InquiryStatus(GeneratedID))
        );

        //CHECK EMAIL ADDRESS AFFECTED IN CONFIRMATION
        const Email = this.arrInquireItem
          .filter(
            (item) =>
              this.toStore.InquiryType == item.Category &&
              this.toStore.InquiryItem == item.InquiryItem
          )
          .map((el) => {
            return this.userType == "Ichijo"
              ? {
                  Email: el.EmailIchijo || "",
                  Receiver: el.IchijoIncharge || "",
                  Cc: el.CcIchijo ? JSON.parse(el.CcIchijo).join(",") : "",
                }
              : {
                  Email: el.EmailGc || "",
                  Receiver: el.GcIncharge || "",
                  Cc: el.CcGc ? JSON.parse(el.CcGc).join(",") : "",
                };
          })[0];

        //SENDING EMAIL FUNCTION
        const sendingEmail = async () => {
          await ConfirmationTable.Get().then(async (res) => {
            const data = {
              dataLength: res.data.length,
              InquiryType: this.toStore.InquiryType,
              Due: this.toStore.TypeOfConfirmation,
              CustomerCode: this.toStore.CustomerCode,
              RuleBookNumber: this.toStore.RuleBookNumber,
              Content: this.content,
              ConfirmationID: GeneratedID,
              Receiver: Email.Receiver,
            };
            // CHECK IF !NULL EMAIL THEN SEND EMAIL CONFIRMATION
            if (Email.Email)
              await EmailTable.send(
                Email.Email,
                Email.Email,
                Email.Cc,
                EmailTemplate.Template(data),
                this.toStore.CustomerCode,
                this.toStore.InquiryItem,
                this.s3key
              );
          });
        };
        // SEND CALLBACK
        Promise.all(promises).then(() => {
          if (this.files.length === 0) {
            sendingEmail();
            this.waitingDialog = false;
            this.$toast.success(this.$t("dialog.success"), objects.alert);
            setTimeout(() => {
              // location.reload();
              this.$router.push("/inbox");
            }, 500);
          }
        });
      }
    },
    //*************************************//
    //        FOR INQUIRY STATUS           //
    //*************************************//

    InquiryStatus(ConfirmationID) {
      const data = {
        ReceivedDate: moment().format("YYYY-MM-DD"),
        ReceivedTime: moment().format("HH:mm:ss"),
        DueDate: this.toStore.TypeOfConfirmation,
        SenderStatus: this.userType,
        SenderName: this.name,
        InquiryType: this.toStore.InquiryType,
        InquiryItem: this.toStore.InquiryItem,
        CustomerCode: this.customerCode,
        DocumentNumber: this.rulebookNumber,
        ContentOfInquiry: this.content,
        ConfirmationID: ConfirmationID,
      };

      const category = this.arrInquireItem
        .filter(
          (item) =>
            this.toStore.InquiryType == item.Category &&
            this.toStore.InquiryItem == item.InquiryItem
        )
        .map((el) => {
          return {
            HandleStaff:
              this.userType == "Ichijo" ? el.IchijoIncharge || "" : el.GcIncharge || "",
            Department: el.Department,
          };
        })[0];
      data.HandleStaff = category.HandleStaff;
      data.Department = category.Department;

      return data;
    },
    //*********************//
    //  GET INQUIRY ITEM   //
    //*********************//

    email() {
      if (this.toStore.InquiryItem) {
        const itemValue = this.arrInquireItem.filter((res) => {
          return res.InquiryItem === this.toStore.InquiryItem;
        });

        // CHECK FOR USER TYPE
        this.toStore.To =
          this.userType === "Ichijo"
            ? itemValue[0].IchijoIncharge
            : itemValue[0].GcIncharge;
        // SUB HANDLE
        if (this.userType === "Ichijo")
          if (itemValue[0].CcIchijoIncharge)
            this.toStore.Cc = itemValue[0].CcIchijoIncharge;
          else {
            if (itemValue[0].CcGcIncharge) this.toStore.Cc = itemValue[0].CcGcIncharge;
          }
      }
    },
    //************************************//
    //     HANDLE REMOVING ATTACHMENT     //
    //************************************//

    async removeAttachment(index, val) {
      const attach = [];

      attach.push({
        OriginalName: val.OriginalName,
      });
      attachment.delete(this.toStore.Path, attach);
      this.UserAttachment.splice(index, 1);
      await DraftTable.Update(this.toStore.ID);
    },
    //************************************//
    //    HANDLE DOWNLOADING ATTACHMENT   //
    //************************************//

    DownloadData(val) {
      attachment.download(this.toStore.Path, val);
    },
    //************************************//
    //    GET EXISTING DRAFT RECORD       //
    //************************************//

    async getDraftRecord() {
      await DraftTable.GetSpecific(this.$route.params.id).then((res) => {
        this.toStore.CustomerCode = res.data[0].CustomerCode;
        this.toStore.RuleBookNumber = res.data[0].RuleBookNumber;
        this.toStore.ID = res.data[0].id;
        if (res.data[0].InquiryType) {
          this.toStore.InquiryType = res.data[0].InquiryType;
          this.inquiretype(res.data[0].InquiryType);
        }
        this.toStore.TypeOfConfirmation = res.data[0].TypeOfConfirmation;

        if (res.data[0].Path) this.toStore.Path = res.data[0].Path;

        if (res.data[0].InquiryItem) {
          this.toStore.InquiryItem = res.data[0].InquiryItem;
          this.toStore.To = res.data[0].To;
        }
        if (res.data[0].Cc) this.toStore.Cc = res.data[0].Cc;

        this.toStore.Subject = res.data[0].Subject;
        this.UserAttachment = [];

        // CHECK FOR ATTACHMENT
        if (res.data[0].Attachment) {
          const attachment = res.data[0].Attachment.split(",");
          for (let x in attachment) {
            const newName = attachment[x].split("_");
            this.UserAttachment.push({
              OriginalName: attachment[x],
              ClientName: newName[1],
            });
          }
        } else this.UserAttachment = [];
        // GET MESSAGE AND HANDLE TEXT FORMAT
        if (res.data[0].MessageContent) {
          const msgVal = res.data[0].MessageContent.split("<br>");
          let newMsg = [];
          for (let i in msgVal) {
            newMsg.push(msgVal[i]);
          }
          this.content = newMsg.join("\n");
          this.messageContent();
        } else this.content = res.data[0].MessageContent;

        console.log(this.toStore);
      });
    },
    //**************************//
    //    GET INQUIRY TYPE      //
    //**************************//

    async inquiretype(val) {
      this.arrInquireItem = [];
      const item = this.radioValue.filter(
        (el) => el.Japanese == val || el.English == val
      )[0].id;

      await CategoryTable.getCategory(item).then((res) => {
        if (res.data != "No Data Found!") {
          // FILTER INQUIRY CATEGORY BASED ON USER TYPE
          // let categoryItem =
          res.data = res.data.filter((el) => {
            el.EmailIchijo || el.IchijoIncharge;
            return (el.categoryItem = `${el.InquiryItem} (${el.EmailIchijo})`);
          });
          console.log(res.data);
          this.arrInquireItem = res.data;
          this.toDepartment = JSON.parse(res.data[0].Department)[0];
          this.getHoliday();
        }
      });
    },
    //**************************//
    //   HANDLE MESSAGE FORMAT  //
    //**************************//

    messageContent() {
      const message = this.content.split("\n");
      let NewMessage = [];
      for (let i in message) NewMessage.push(message[i]);

      this.toStore.MessageContent = NewMessage.join("<br>");
    },
    //**************************//
    //   HANDLE FILE UPLOADING  //
    //**************************//

    handleFileUploads() {
      this.files = []; //FILES TO BE SEND IN S3
      this.attachment = []; //FILENAME TO BE SAVE IN DATABASE
      for (let x in this.tempFile) this.files.push(this.tempFile[x].file);
      if (this.files.length != 0) {
        for (let i = 0; i < this.files.length; i++) {
          const attachment = this.files[i].name;
          this.attachment.push(attachment);
          // let previewFile = URL.createObjectURL(files[i]);
          // window.open(previewFile);
        }
      }
    },
    //********************************//
    //   HANDLE RESIZE RESPONSIVENESS //
    //********************************//

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
<style lang="scss">
.bottomArrangements {
  margin-bottom: -4%;
}
.filepond--root {
  height: 8em;
}
</style>
